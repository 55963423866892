/* NavyPanel.css */
:root {
  --primary-bg: #E6F3FF;
  --primary-text: #1A3A5A;
  --secondary-text: #3A5A7A;
  --accent-color: #4A90E2;
  --button-bg: #2C3E50;
  --button-text: #FFFFFF;
  --search-bg: #FFFFFF;
  --search-text: #333333;
  --search-icon: #4A90E2;
}

.navy-panel {
  background-color: var(--primary-bg);
  color: var(--primary-text);
  padding: 2rem;
}
  
.navy-panel h1 {
  font-size: 4rem;
  color: var(--primary-text);
  text-align: center;
  display: flex;
  justify-content: center;
  font-family: Arial, Helvetica, sans-serif;
}

.navy-panel p {
  font-size: 2rem;
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  color: var(--secondary-text);
}

.search-bar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

.search-bar-wrapper {
  display: flex;
  align-items: center;
  background-color: var(--search-bg);
  border-radius: 25px;
  padding: 5px 10px;
  width: 80%;
  max-width: 500px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  overflow: hidden;
}

.search-icon {
  margin-right: 10px;
  color: var(--search-icon);
}

.search-bar {
  padding: 10px;
  border: none;
  border-radius: 25px;
  flex: 1;
  font-size: 16px;
  outline: none;
  color: var(--search-text);
  flex-grow: 1;
}

.search-button {
  background-color: var(--button-bg);
  color: var(--button-text);
  border: none;
  border-radius: 25px;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.search-button:hover {
  background-color: var(--accent-color);
}

/* Media query for devices with a max width of 768px */
@media (max-width: 500px) {
    .navy-panel {
      padding: 20px;
    }
  
    .navy-panel h1 {
      font-size: 20px;
    }

    .navy-panel p {
      font-size: 1rem;
    }

    .search-bar-wrapper {
      border-radius: 20px;
      padding: 5px 14px;
    }
    
    .search-bar {
      padding: 12px;
      font-size: 14px;
    }
    
    .search-button {
      padding: 8px 12px;
      font-size: 12px;
      width: 25%;
    }
}