:root {
  --navbar-bg: #FFFFFF;
  --navbar-border: #E6F3FF;
  --navbar-shadow: rgba(0, 0, 0, 0.1);
  --navbar-text: #1A3A5A;
  --navbar-hover: #4A90E2;
  --button-highlight: rgba(74, 144, 226, 0.1);
}

.navbar-wrapper {
  background-color: var(--navbar-bg);
  border-bottom: 2px solid var(--navbar-border);
  padding: 0.5rem;
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  width: 100%;
  max-width: 1800px;
  margin: 0 auto;
  position: relative;
}

/* Logo Styles */
.navbar-logo {
  height: 60px;
  transition: transform 0.3s ease;
  z-index: 1;
}

.navbar-logo:hover {
  transform: scale(1.05);
}

/* City Selector Styles */
.navbar-city-selector {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  width: auto;
}

.city-selector-text {
  font-size: 18px;
  font-weight: 600;
  color: var(--navbar-text);
  padding: 8px 16px;
  transition: all 0.3s ease;
  border-radius: 8px;
  white-space: nowrap;
}

.city-selector-text:hover {
  color: var(--navbar-hover);
  background-color: var(--button-highlight);
}

/* Navbar Actions */
.navbar-actions {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  z-index: 1;
}

.navbar-link {
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  color: var(--navbar-text);
  padding: 0.6rem 1.2rem;
  border-radius: 8px;
  transition: all 0.3s ease;
  background: transparent;
  border: none;
}

.navbar-link:hover {
  color: var(--navbar-hover);
  background-color: var(--button-highlight);
  transform: translateY(-2px);
}

/* Hamburger Menu */
.navbar-hamburger {
  display: none;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 6px;
  transition: background-color 0.3s ease;
}

.navbar-hamburger:hover {
  background-color: var(--button-highlight);
}

.hamburger-icon {
  width: 25px;
  height: 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.hamburger-icon span {
  width: 100%;
  height: 2px;
  background-color: var(--navbar-text);
  transition: all 0.3s ease;
  border-radius: 2px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .navbar-wrapper {
    padding: 0.5rem 1rem;
  }

  .navbar-container {
    align-items: center;
    padding: 10px;
  }

  .navbar-hamburger {
    display: block;
    margin-right: 10px;
  }

  .navbar-actions {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 100%;
    right: 1rem;
    background-color: var(--navbar-bg);
    padding: 1rem;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border: 1px solid var(--navbar-border);
    border-radius: 12px;
    gap: 0.75rem;
    min-width: 200px;
  }

  .navbar-actions.open {
    display: flex;
  }

  .navbar-logo {
    height: 50px;
    margin-left: -10px;
  }

  .navbar-link {
    font-size: 0.9rem;
    padding: 0.5rem;
    width: 100%;
    text-align: center;
  }

  .navbar-city-selector {
    position: relative;
    left: auto;
    transform: none;
    margin-left: 20px;
  }

  .city-selector-text {
    font-size: 16px;
    padding: 6px 12px;
  }
}

/* Animation for mobile menu */
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.navbar-actions.open {
  animation: slideIn 0.3s ease forwards;
}